// https://vike.dev/onRenderClient
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Layout } from './Layout';
import { getPageTitle } from './getPageTitle';
import type { OnRenderClientAsync } from 'vike/types';

import { HelmetProvider } from '@vuer-ai/react-helmet-async';

let root: ReactDOM.Root;
export const onRenderClient: OnRenderClientAsync = async (pageContext): ReturnType<OnRenderClientAsync> => {
  console.info(`
  React developer tools may run twice in development mode because of
  StrictMode, a feature that helps developers find bugs by simulating
  component mounting, unmounting, and remounting. StrictMode 
  intentionally invokes lifecycle methods and effects multiple times,
  including extra calls to render functions and effect hooks. For example,
  StrictMode will mount, unmount, and then remount every component, causing
  useEffect to be executed twice. This is done to ensure that effects are 
  resilient to remounting so that certain optimizations can be performed 
  safely. 
  
  https://tinyurl.com/42jmy7yj
  `)
  const { Page } = pageContext;
  const page = (
    <Layout pageContext={pageContext}>
      <HelmetProvider>
        <Page />
      </HelmetProvider>
    </Layout>
  );
  const container = document.getElementById('react-root')!;
  if (pageContext.isHydration) {
    root = ReactDOM.hydrateRoot(container, page);
  } else {
    if (!root) {
      root = ReactDOM.createRoot(container);
    }
    root.render(page);
  }
  document.title = getPageTitle(pageContext);
};
